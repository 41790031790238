import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Event } from '../models/event';
import { Subscription } from 'rxjs';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-bookings-stats',
  templateUrl: './bookings-stats.component.html',
  styleUrls: ['./bookings-stats.component.scss']
})
export class BookingsStatsComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  events: Event[];

  // Change to Subscription type to match RxJS
  eventSubscription: Subscription;

  //Sales
  bookingsLength: number;
  adminBookings: number;
  guestsBookings: number;
  booked: number;
  available: number;
  checkedIn: number;
  totalSales: number;
  waitingList: number;

  loading: boolean = true;

  constructor(private eventService: EventService) { }

  ngOnInit(): void {
  }  
  
  ngOnDestroy(): void {
    // Clean up subscription when component is destroyed
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    //Only execute when there is a change
    this.setupStats(
      changes.events?.currentValue || this.events );
      this.maybeListenToEventChanges();
  }


  maybeListenToEventChanges(): void {
    // First unsubscribe from previous subscription if it exists
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    
    // Then set up new subscription if we have exactly one event
    if (this.events?.length == 1) {
      console.log("Listening to event changes", this.events[0].id);
      
      this.eventSubscription = this.eventService.getEventInstance(
        this.events[0].id, 
        this.events[0].startDate?.seconds, 
        this.events[0].venueObj?.name
      ).subscribe((event: Event) => {
        console.log("Event changed", event);
        this.setupStats([event]);
      });
    }
  }

  setupStats(events: Event[]) {
    const bookingsStats = events?.map(e => e.bookingsStats) || [];
    if (bookingsStats?.length > 0) {
      this.bookingsLength = bookingsStats.reduce((pv, cv) => pv + (cv?.bookingsCount || 0), 0);
      this.adminBookings = bookingsStats.reduce((pv, cv) => pv + (cv?.adminBookings || 0), 0)
      this.guestsBookings = this.bookingsLength - this.adminBookings;
      this.waitingList = bookingsStats.reduce((pv, cv) => pv + (cv?.waitingListTickets || 0), 0);
      this.booked = bookingsStats.reduce((pv, cv) => pv + (cv?.bookedTickets || 0), 0);
      this.available = bookingsStats.reduce((pv, cv) => pv + (cv?.availableTickets || 0), 0);
      this.checkedIn = bookingsStats.reduce((pv, cv) => pv + (cv?.attendedTickets || 0), 0);
      this.totalSales = bookingsStats.reduce((pv, cv) => pv + (cv?.totalSales || 0), 0);
      this.loading = false;
    } else {
      this.loading = false;
      this.bookingsLength = 0;
      this.adminBookings = 0;
      this.guestsBookings = 0;
      this.booked = 0;
      this.available = 0;
      this.checkedIn = 0;
      this.waitingList = 0;
      this.totalSales = 0;
    }
  }
}
