import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-how-to-add-event',
  templateUrl: './how-to-add-event.component.html',
  styleUrls: ['./how-to-add-event.component.scss']
})
export class HowToAddEventComponent implements OnInit {
  siteName: string;

  constructor(
    private titleService: Title,
    public translate: TranslateService
  ) {
    this.siteName = environment.content.siteName;
  }

  ngOnInit(): void {
    this.translate.get('HOW_TO_ADD_EVENT.TITLE').subscribe(res => {
      this.titleService.setTitle(res + ' | ' + this.siteName);
    });
  }
}
