import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Campaign } from '../models/campaign';
import { CampaignsService } from '../services/campaigns.service';
import { TranslateService } from '@ngx-translate/core';
import { QuestionWarningComponent } from '../question-warning/question-warning.component';

interface CampaignRecipient {
  email: string;
  removing?: boolean;
}

@Component({
  selector: 'app-campaign-recipients',
  templateUrl: './campaign-recipients.component.html',
  styleUrls: ['./campaign-recipients.component.scss']
})
export class CampaignRecipientsComponent implements OnInit {
  campaign: Campaign;
  campaignId: string;
  allRecipients: CampaignRecipient[] = [];
  filteredRecipients: CampaignRecipient[] = [];
  searchText = '';
  loading = true;
  error: string = null;

  constructor(
    private route: ActivatedRoute,
    private campaignsService: CampaignsService,
    public translate: TranslateService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.campaignId = this.route.snapshot.paramMap.get('id');
    if (this.campaignId) {
      this.loadCampaignData();
    }
  }

  async loadCampaignData() {
    try {
      this.loading = true;
      this.error = null;
      
      if (this.campaignId === 'project-subscribers') {
        // This is the project-wide subscribers list
        this.campaign = {
          name: 'Project Subscribers',
          listName: null, // Will be populated from config.ts in the cloud function
          id: 'project-subscribers'
        };
        
        // Load project subscribers
        const recipients = await this.campaignsService.getProjectSubscribers();
        this.allRecipients = recipients.map(email => ({ email }));
        this.filteredRecipients = [...this.allRecipients];
      } else {
        // Regular campaign recipients
        // Load campaign details
        this.campaign = await this.campaignsService.getCampaign(this.campaignId);
        
        // Load recipients
        const recipients = await this.campaignsService.getCampaignRecipients(this.campaignId);
        this.allRecipients = recipients.map(email => ({ email }));
        this.filteredRecipients = [...this.allRecipients];
      }
    } catch (err) {
      console.error('Error loading campaign recipients:', err);
      this.error = err.message || 'Failed to load recipients';
    } finally {
      this.loading = false;
    }
  }

  applyFilter() {
    if (!this.searchText.trim()) {
      this.filteredRecipients = [...this.allRecipients];
      return;
    }
    
    const filterValue = this.searchText.toLowerCase().trim();
    this.filteredRecipients = this.allRecipients.filter(
      recipient => recipient.email.toLowerCase().includes(filterValue)
    );
  }

  clearSearch() {
    this.searchText = '';
    this.applyFilter();
  }

  openRemoveDialog(recipient: CampaignRecipient) {
    const dialogRef = this.dialog.open(QuestionWarningComponent, {
      width: '400px',
      data: {
        title: this.translate.instant('Remove Recipient'),
        content: this.translate.instant('Are you sure you want to remove this recipient?'),
        recipient: recipient.email
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeRecipient(recipient);
      }
    });
  }

  async removeRecipient(recipient: CampaignRecipient) {
    try {
      // Mark as removing (for UI spinner)
      recipient.removing = true;
      
      if (this.campaignId === 'project-subscribers') {
        // Remove from project-wide subscribers list
        await this.campaignsService.removeProjectSubscriber(recipient.email);
      } else {
        // Remove from campaign recipients
        await this.campaignsService.removeRecipientFromCampaign(
          this.campaignId, 
          this.campaign.listName, 
          recipient.email
        );
        
        // Update campaign recipient count in Firestore
        if (this.campaign.recipients > 0) {
          await this.campaignsService.updateCampaign(this.campaignId, {
            recipients: this.campaign.recipients - 1
          });
          this.campaign.recipients--;
        }
      }
      
      // Remove from local arrays
      this.allRecipients = this.allRecipients.filter(r => r.email !== recipient.email);
      this.applyFilter(); // Re-filter to update the view
    } catch (err) {
      console.error('Error removing recipient:', err);
      recipient.removing = false;
    }
  }
}