<div *ngIf="environment.theme == 'classic'" class="classic">
  <div class="about-container">
    <div class="wrapper" *ngIf="environment.ui.showWelcomeMessage">
      <h1 class="mat-h1"><span
          *ngIf="translate.currentLang  == 'nb'">{{environment.content.siteNameTranslatable.nb}}</span><span
          *ngIf="translate.currentLang  != 'nb'">{{environment.content.siteNameTranslatable.en}}</span></h1>
      <div class="about mat-body">
        <div *ngIf="translate.currentLang  == 'nb'" [innerHtml]="environment.content.welcomeMessage.nb">
        </div>
        <div *ngIf="translate.currentLang != 'nb'" [innerHtml]="environment.content.welcomeMessage.en">
        </div>
      </div>
    </div>
  </div>
  <div class="footer-upper" *ngIf="environment.newsletterEnabled">
    <div class="footer-upper-content wrapper">
      <div class="newsletter full-width" fxLayout="column" fxLayoutAlign="center center">
        <app-newsletter-footer></app-newsletter-footer>
      </div>
    </div>
  </div>
  <div
    [ngClass]="!consentAccepted ? 'footer-lower extra-bottom-padding background-primary' : 'footer-lower background-primary'">
    <div class="footer-lower-content wrapper">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="start start" fxLayoutAlign="start start">
        <div *ngIf="environment.ui.showOnFooter.organizers" fxFlex="50" fxLayout="column" fxLayoutAlign.xs="start start"
          fxLayoutAlign="space-around start">
          <div class="mat-body-2 header" translate>For organizers</div>
          <div *ngIf="environment.ui.showOnFooter.why" class="mat-body-1 footer-link"><a routerLink="/why"><span
                translate>Why use</span> {{environment.content.siteName}}</a></div>
          <div *ngIf="environment.ui.showOnFooter.tips" class="mat-body-1 footer-link"><a routerLink="/tips-for-organizers">
            {{'Criteria and tips for events' | translate}}</a></div>
          <div *ngIf="environment.ui.showOnFooter.howToAddEvent" class="mat-body-1 footer-link"><a routerLink="/how-to-add-an-event">
            {{'HOW_TO_ADD_EVENT.LINK' | translate}}</a></div>
          <div *ngIf="environment.ui.showOnFooter.feed" class="mat-body-1 footer-link"><a translate
              routerLink="/feed">Feed to your website</a></div>
          <div *ngIf="environment.ui.showOnFooter.ads" class="mat-body-1 footer-link"><a translate
              routerLink="/advertising">Advertising</a></div>
        </div>
        <div fxFlex="50" *ngIf="environment.ui.showOnFooter.aboutPage" ngClass.xs="margin-top" fxLayout="column" fxLayoutAlign.xs="start start"
          fxLayoutAlign="space-around start">
          <div class="mat-body-2 header"><span translate>About</span> «{{environment.content.siteName}}»</div>
          <div *ngIf="environment.ui.showOnFooter.aboutus" class="mat-body-1 footer-link"><a
              routerLink="/about-us">{{'About us' | translate}}</a></div>
          <div *ngIf="environment.ui.showOnFooter.what" class="mat-body-1 footer-link"><a routerLink="/what"><span
                translate>What is</span> {{environment.content.siteName}}</a></div>
          <div *ngIf="environment.ui.showOnFooter.privacy" class="mat-body-1 footer-link"><a translate
              routerLink="/privacy-policy">Privacy Policy</a></div>
          <div *ngIf="environment.ui.showOnFooter.availabilityDeclarationLink" class="mat-body-1 footer-link"><a
              translate [href]="environment.ui.showOnFooter.availabilityDeclarationLink" target="_blank">Declaration of
              availability</a></div>
          <div *ngIf="environment.ui.showOnFooter.contact" class="mat-body-1 footer-link"><a translate
              routerLink="/contact-form">Contact</a></div>
          <div *ngIf="environment.ui.showOnFooter.contactExternalLink" class="mat-body-1 footer-link"><a translate
              target="_blank" [href]="environment.ui.showOnFooter.contactExternalLink">Contact</a></div>
          <div *ngIf="environment.ui.showOnFooter.facebook" class="mat-body-1 footer-link"><a translate target="_blank"
              [href]="'https://facebook.com/' + environment.contact.facebookSlug">Facebook page</a></div>
        </div>
      </div>

      <div class="notice background-primary" fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutGap="20px"
      *ngIf="!consentAccepted && !warningBrowser && (environment.cookies.analytics || environment.cookies.login.google || environment.cookies.login.facebook)"> 
          <div>
            {{'This website uses cookies for' | translate}}
            <span *ngIf="environment.cookies.analytics">{{'Google Analytics' | translate}}</span>
            <span *ngIf="environment.cookies.analytics && (environment.cookies.login.google || environment.cookies.login.facebook)"> {{'and' | translate}}</span>
            <span *ngIf="environment.cookies.login.google"> {{'Google login' | translate}}</span>
            <span *ngIf="environment.cookies.login.google && environment.cookies.login.facebook"> {{'and' | translate}}</span>
            <span *ngIf="environment.cookies.login.facebook"> {{'Facebook login' | translate}}</span>
            {{'for analytics and authentication purposes' | translate}}.
          </div>
          <div><button mat-raised-button (click)="acceptConsent()">{{'I understand' | translate}}</button></div>
      </div>

      <div class="notice reload background-primary" fxLayout="column" fxLayoutAlign="space-evenly center"
        fxLayoutGap="20px" *ngIf="showReloadWarning">
        <div translate>A new version of this site is available</div>
        <div><button mat-raised-button (click)="reloadPage()">{{'Refresh' | translate}}</button></div>
      </div>

      <div class="notice background-primary" fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutGap="20px"
        *ngIf="warningBrowser">
        <div translate>This website is not fully supported for your browser. Please, use one of the following browsers:
        </div>
        <div class="button-container" fxLayout="row" fxLayoutGap="10px">
          <a href="https://www.google.com/chrome/" target="_blank"><img
              src="https://cdnjs.cloudflare.com/ajax/libs/browser-logos/51.0.2/archive/chrome_12-48/chrome_12-48_48x48.png" /></a>
          <a href="https://www.mozilla.org/nb-NO/firefox/" target="_blank"><img
              src="https://cdnjs.cloudflare.com/ajax/libs/browser-logos/51.0.2/archive/firefox_3.5-22/firefox_3.5-22_48x48.png" /></a>
          <a href="https://www.opera.com/download" target="_blank"><img
              src="https://cdnjs.cloudflare.com/ajax/libs/browser-logos/51.0.2/archive/opera_10-14/opera_10-14_48x48.png" /></a>
          <a href="https://support.apple.com/downloads/safari" target="_blank"><img
              src="https://cdnjs.cloudflare.com/ajax/libs/browser-logos/51.0.12/safari/safari_48x48.png" /></a>
          <a href="https://www.microsoft.com/en-us/edge/download" target="_blank"><img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Microsoft_Edge_logo_%282019%29.png/240px-Microsoft_Edge_logo_%282019%29.png" /></a>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="footer-bottom-content wrapper">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
        <div fxFlex="50" class="credit">©{{today | date:'yyyy'}} <a target="_blank"
            [href]="environment.owner.link">{{environment.owner.name}}</a></div>
        <div fxFlex="50" class="credit"><a target="_blank" href="https://hvaskjerkalender.no">© HvaSkjerKalender.no</a>
        </div>
      </div>
    </div>
  </div>

</div>
<div *ngIf="environment.theme == 'trdevents'" fxLayout="column" fxLayoutGap="40px" class="trdevents">
  <div *ngIf="!isVMFest" fxLayout="row" fxLayoutAlign="start start" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center" fxLayoutGap="40px">
    <div fxFlexOrder.xs="2" class="left" fxFlex="50" fxLayout="column" fxLayoutAlign="center center">
      <div class="title">{{'Contact information' | translate}}</div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
        <div>Trondheim Management</div>
        <div>Nordre gate 10, 7011 Trondheim</div>
        <div><a class="email" href="mailto:post@midtbyen.no">{{'post@midtbyen.no'}}</a></div>
        <div>+47 993 54 000</div>
        <div>Org. nr 995 860 465</div>
        <a href="https://www.facebook.com/HvaSkjerITrondheim" target="_blank"><img src="../../assets/logos/facebook-orange.png"/></a>
      </div>
    </div>
    <div fxFlexOrder.xs="1" class="right" fxFlex="50" fxLayout="row" fxLayoutAlign="center center">
      <app-newsletter-footer></app-newsletter-footer>
    </div>
  </div>
  <div *ngIf="isVMFest" class="margin-bottom margin-top" fxLayout="row" fxLayoutAlign="center center">
    <img fxFlex="300px" fxFlex.xs="200px" class="margin-bottom" src="../../assets/logos/vmfest-logo-footer.png"/>
  </div>
  <div *ngIf="isVMFest" class="vm-fest-footer-block" fxLayout="column" fxLayoutAlign="center center">
    <div class="title">{{'Contact information' | translate}}</div>
    <div class="contact" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
      <div>VM-Fest</div>
      <div>Erling Skakkes gate 14</div>
      <div>7013 Trondheim</div>
      <div><a class="email" href="mailto:vm-fest@trondheim2025.no">{{'vm-fest@trondheim2025.no'}}</a></div>
    </div>
  </div>
  <div *ngIf="isVMFest" class="vm-fest-footer-block" fxLayout="column" fxLayoutAlign="center center">
    <div class="title">{{'Sponsored by' | translate}}</div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
      <img class="sponsor" src="https://www.sparebank1.no/content/dam/SB1/bank/smn/om-oss/logo/nedlasting/sparebank1smn/skjerm/rgb_SB1_SMN_verti_bw_neg.png"/>
    </div>
  </div>
  <div class="full-width bottom margin-top" fxLayout="column" fxLayoutAlign="center center">
    <div class="title">{{'Powered by' | translate}}</div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
      <img src="../../assets/logos/midtbyen-name.png"/>
      <img src="../../assets/logos/visit-trondheim.png"/>
    </div>
  </div>
</div>