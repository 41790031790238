<div class="filter-container custom-search">
  <div class="input-container omni-box" [ngClass]="omniFocused ? 'inverted-color' : ''" fxLayout="row">
    <input fxFlex="grow" autocomplete="off" type="search" (focus)="onOmniFocused()" (blur)="onOmniBlur()"
      [formControl]="omni" matInput
      [placeholder]="'Search in events' | translate" #omniInput>
    <button class="clear" *ngIf="omniInput.value?.length > 0" (click)="clear()" mat-icon-button>
      <mat-icon fxHide.lt-md fxShow.gt-sm>clear</mat-icon>
      <mat-icon fxHide.gt-sm fxShow.lt-md>highlight_off</mat-icon>
    </button>
  </div>
</div>