<div class="box">
    <div class="box-label">{{'List overview stats' | translate}}</div>
    <div class="bookings-stats" fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start start" fxLayoutGap="30px">
        <div>
            <div class="label">{{'Number of bookings' | translate}}</div>
            <div class="value" *ngIf="!loading">{{bookingsLength}}</div>
            <div class="value" *ngIf="loading">{{'Loading' | translate}}...</div>
        </div>
        <div>
            <div class="label">{{'Tickets ordered' | translate}} / {{'Tickets available' | translate}}</div>
            <div class="value" *ngIf="!loading">{{booked}} / {{available}} ({{(booked / available) | percent}})</div>
            <div class="value" *ngIf="loading">{{'Loading' | translate}}...</div>
        </div>
        <div>
            <div class="label">{{'Tickets in waiting list' | translate}}</div>
            <div class="value" *ngIf="!loading">{{waitingList}}</div>
            <div class="value" *ngIf="loading">{{'Loading' | translate}}...</div>
        </div>
        <div>
            <div class="label">{{'Admin bookings' | translate}} / {{'Guests bookings' | translate}}</div>
            <div class="value" *ngIf="!loading">{{adminBookings}} / {{guestsBookings}} ({{ (adminBookings /
                (adminBookings + guestsBookings))
                | percent}} / {{(guestsBookings / (adminBookings + guestsBookings))
                | percent}})</div>
            <div class="value" *ngIf="loading">{{'Loading' | translate}}...</div>
        </div>
        <div>
            <div class="label">{{'Attendance' | translate}}</div>
            <div class="value" *ngIf="!loading">{{checkedIn}} / {{booked}} ({{(checkedIn / booked) | percent}})</div>
            <div class="value" *ngIf="loading">{{'Loading' | translate}}...</div>
        </div>
        <div>
            <div class="label">{{'Total sales' | translate }}</div>
            <div class="value" *ngIf="!loading">{{totalSales | currency:'NOK':'symbol':'0.2-2':'nb'}}</div>
            <div class="value" *ngIf="loading">{{'Loading' | translate}}...</div>
        </div>
    </div>
</div>