import { NgModule, ErrorHandler, inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, registerLocaleData } from '@angular/common';
import localeNb from '@angular/common/locales/nb';

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAnalytics, getAnalytics, UserTrackingService, ScreenTrackingService} from '@angular/fire/analytics';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideAuth, getAuth, useDeviceLanguage } from '@angular/fire/auth';
import { provideFunctions, getFunctions } from '@angular/fire/functions';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule, LayoutGapStyleBuilder } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MaterialModule } from './modules/material.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ScriptLoaderModule } from 'ngx-script-loader';
import { NgxImageCompressService } from 'ngx-image-compress';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


import { environment } from './../environments/environment';

import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { MaybeOnlyRegisteredGuard } from './guards/maybe-only-registered.guard';
import { EventService } from './services/event.service';
import { EventListService } from './services/event-list.service';
import { CategoryService } from './services/category.service';
import { TicketTypeService } from './services/ticket-type.service';
import { BookingStatusService } from './services/booking-status.service';
import { VenueService } from './services/venue.service';
import { OrganizerService } from './services/organizer.service';
import { UtilsService } from './services/utils.service';
import { MapsService } from './services/maps.service';
import { ImportService } from './services/import.service';
import { NewsletterService } from './services/newsletter.service';
import { SeoService } from './services/seo.service';
import { RepetitionService } from './services/repetition.service';
import { ScrollService } from './services/scroll.service';
import { TeamService } from './services/team.service';
import { CampaignsService } from './services/campaigns.service';


import { AppComponent } from './app.component';
import { EventFormComponent } from './event-form/event-form.component';
import { EventListComponent } from './event-list/event-list.component';
import { EventSingleComponent } from './event-single/event-single.component';
import { EventListItemComponent } from './event-list-item/event-list-item.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AccountComponent } from './account/account.component';
import { EventEditionComponent } from './event-edition/event-edition.component';
import { AddVenueComponent } from './add-venue/add-venue.component';
import { LoginProviderComponent } from './login-provider/login-provider.component';
import { FeedComponent } from './feed/feed.component';
import { ContactComponent } from './contact/contact.component';
import { MyEventsComponent } from './my-events/my-events.component';
import { AddOrganizerComponent } from './add-organizer/add-organizer.component';
import { LoginScreenComponent } from './login-screen/login-screen.component';
import { MyEventsListItemComponent } from './my-events-list-item/my-events-list-item.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { NewsletterFormComponent } from './newsletter-form/newsletter-form.component';
import { AppRoutingModule } from './app-routing.module';
import { AddRecurrenceComponent } from './add-recurrence/add-recurrence.component';
import { ManualRepetitionComponent } from './manual-repetition/manual-repetition.component';
import { EventRepetitionComponent } from './event-repetition/event-repetition.component';
import { ImportFacebookComponent } from './import-facebook/import-facebook.component';
import { AdvertisingComponent } from './advertising/advertising.component';
import { WhatIsComponent } from './what-is/what-is.component';
import { WhyUseComponent } from './why-use/why-use.component';
import { QuestionWarningComponent } from './question-warning/question-warning.component';
import { NewsletterFooterComponent } from './newsletter-footer/newsletter-footer.component';
import { SmartFilterComponent } from './smart-filter/smart-filter.component';
import { MyTeamsComponent } from './my-teams/my-teams.component';
import { MyTeamsListItemComponent } from './my-teams-list-item/my-teams-list-item.component';
import { AddTeamComponent } from './add-team/add-team.component';
import { AdminComponent } from './admin/admin.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';


import { RollbarService, rollbarFactory, RollbarErrorHandler } from './modules/rollbar';

import { UnsubscribeEmailComponent } from './unsubscribe-email/unsubscribe-email.component';
import { MyJsonLdComponent } from './my-json-ld/my-json-ld.component';
import { CustomLayoutGapStyleBuilder } from './modules/custom-layout-gap-style-builder';
import { ImageCarrouselComponent } from './image-carrousel/image-carrousel.component';
import { ImageGuidelinesComponent } from './image-guidelines/image-guidelines.component';
import { MetaLinkComponent } from './meta-link/meta-link.component';
import { PublishingRulesComponent } from './publishing-rules/publishing-rules.component';
import { QuestionPasswordComponent } from './question-password/question-password.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CampaignComponent } from './campaign/campaign.component';
import { SendTestCampaignComponent } from './send-test-campaign/send-test-campaign.component';
import { RepetitionsDialogComponent } from './repetitions-dialog/repetitions-dialog.component';
import { ReportEventComponent } from './report-event/report-event.component';
import { HighlightPipe } from './pipes/highlight';
import { PhonePipe } from './pipes/phone';
import { BookingService } from './services/booking.service';
import { CustomerService } from './services/customer.service';
import { BookingFormComponent } from './booking-form/booking-form.component';
import { BookingsListComponent } from './bookings-list/bookings-list.component';
import { EditTeamComponent } from './edit-team/edit-team.component';
import { WarningsComponent } from './warnings/warnings.component';
import { MenuComponent } from './menu/menu.component';
import { TermsAndConditionsRSVPComponent } from './terms-and-conditions-rsvp/terms-and-conditions-rsvp.component';
import { BookingsStatsComponent } from './bookings-stats/bookings-stats.component';
import { BookingEditionComponent } from './booking-edition/booking-edition.component';
import { TicketSelectorComponent } from './ticket-selector/ticket-selector.component';
import { CustomizedSMSComponent } from './customized-sms/customized-sms.component';
import { CustomerNameEditorComponent } from './customer-name-editor/customer-name-editor.component';
import { BookingLogComponent } from './booking-log/booking-log.component';
import { CustomerAddressEditorComponent } from './customer-address-editor/customer-address-editor.component';
import { CustomerCommentsEditorComponent } from './customer-comments-editor/customer-comments-editor.component';
import { BookingStatusEditorComponent } from './booking-status-editor/booking-status-editor.component';
import { BookingVenueEditorComponent } from './booking-venue-editor/booking-venue-editor.component';
import { BookingStartDateEditorComponent } from './booking-start-date-editor/booking-start-date-editor.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { InDoorCheckInComponent } from './in-door-check-in/in-door-check-in.component';
import { AdminBookingComponent } from './admin-booking/admin-booking.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomerFormComponent } from './customer-form/customer-form.component';
import { CustomerEditionComponent } from './customer-edition/customer-edition.component';
import { CustomerPhoneEditorComponent } from './customer-phone-editor/customer-phone-editor.component';
import { BookingEventSelectorComponent } from './booking-event-selector/booking-event-selector.component';
import { CustomerFormSelectorComponent } from './customer-form-selector/customer-form-selector.component';
import { AdminBookingRowComponent } from './admin-booking-row/admin-booking-row.component';
import { AddTicketTypeComponent } from './add-ticket-type/add-ticket-type.component';
import { ChangeStartDateComponent } from './change-start-date/change-start-date.component';
import { BookingGuestComponent } from './booking-guest/booking-guest.component';
import { MyTicketsComponent } from './my-tickets/my-tickets.component';
import { BookingGuestEditorComponent } from './booking-guest-editor/booking-guest-editor.component';
import { CdkColumnDef } from '@angular/cdk/table';
import { FrontpageComponent } from './frontpage/frontpage.component';
import { EventTileComponent } from './event-tile/event-tile.component';
import { MoreComponent } from './more/more.component';
import { FrontpageEventsComponent } from './frontpage-events/frontpage-events.component';
import { MyFavoritesComponent } from './my-favorites/my-favorites.component';
import { FavoritesService } from './services/favorites.service';
import { FirestoreTimestampPipe } from './pipes/firestore-timestamp.pipe';
import { BookingStatusPipe } from './pipes/booking-status.pipe';
import { LastActionStatusPipe } from './pipes/last-action-status.pipe';
import { MyTicketsPageComponent } from './my-tickets-page/my-tickets-page.component';
import { EnterCodeComponent } from './enter-code/enter-code.component';
import { FilesizePipe } from './pipes/filesize';
import { TrimDirective } from './directives/trim';
import { GroupsComponent } from './groups/groups.component';
import { ParentMeetingsComponent } from './parent-meetings/parent-meetings.component';
import { TipsForOrganizersComponent } from './tips-for-organizers/tips-for-organizers.component';
import { HowToAddEventComponent } from './how-to-add-event/how-to-add-event.component';
import { CampaignRecipientsComponent } from './campaign-recipients/campaign-recipients.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

//Adding the Norwegian locale
registerLocaleData(localeNb, 'nb');

const firebaseImports = [
  provideFirebaseApp(() => initializeApp(environment.firebase)),
  provideFirestore(() => getFirestore()),
  provideStorage(() => getStorage()),
  provideAuth(() => getAuth()),
  provideFunctions(() => getFunctions(undefined, environment.siteURL))
];

if (environment.cookies.analytics) {
  firebaseImports.push(provideAnalytics(() => getAnalytics()));
}


export function getLocalStorage() {
  const platformId = inject(PLATFORM_ID);
  
  if (isPlatformBrowser(platformId) && storageAvailable()) {
    return window.localStorage;
  }
  
  return null;
}

function storageAvailable(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }

  try {
    const storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      window.localStorage &&
      window.localStorage.length !== 0;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    EventFormComponent,
    EventListComponent,
    EventSingleComponent,
    EventListItemComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    PageNotFoundComponent,
    AccountComponent,
    EventEditionComponent,
    LoginProviderComponent,
    FeedComponent,
    ContactComponent,
    MyEventsComponent,
    AddVenueComponent,
    AddOrganizerComponent,
    LoginScreenComponent,
    MyEventsListItemComponent,
    PrivacyPolicyComponent,
    NewsletterComponent,
    NewsletterFormComponent,
    AddRecurrenceComponent,
    ManualRepetitionComponent,
    EventRepetitionComponent,
    ImportFacebookComponent,
    AdvertisingComponent,
    WhatIsComponent,
    WhyUseComponent,
    QuestionWarningComponent,
    NewsletterFooterComponent,
    SmartFilterComponent,
    MyTeamsComponent,
    MyTeamsListItemComponent,
    AddTeamComponent,
    AdminComponent,
    UnsubscribeEmailComponent,
    MyJsonLdComponent,
    ImageUploaderComponent,
    ImageCarrouselComponent,
    ImageGuidelinesComponent,
    MetaLinkComponent,
    PublishingRulesComponent,
    QuestionPasswordComponent,
    CampaignsComponent,
    CampaignComponent,
    SendTestCampaignComponent,
    RepetitionsDialogComponent,
    ReportEventComponent,
    HighlightPipe,
    PhonePipe,
    BookingFormComponent,
    BookingsListComponent,
    EditTeamComponent,
    WarningsComponent,
    MenuComponent,
    TermsAndConditionsRSVPComponent,
    BookingsStatsComponent,
    BookingEditionComponent,
    TicketSelectorComponent,
    CustomizedSMSComponent,
    CustomerNameEditorComponent,
    BookingLogComponent,
    CustomerAddressEditorComponent,
    CustomerCommentsEditorComponent,
    BookingStatusEditorComponent,
    BookingVenueEditorComponent,
    BookingStartDateEditorComponent,
    AboutUsComponent,
    InDoorCheckInComponent,
    AdminBookingComponent,
    CustomersComponent,
    CustomerFormComponent,
    CustomerEditionComponent,
    CustomerPhoneEditorComponent,
    BookingEventSelectorComponent,
    CustomerFormSelectorComponent,
    AdminBookingRowComponent,
    AddTicketTypeComponent,
    ChangeStartDateComponent,
    BookingGuestComponent,
    MyTicketsComponent,
    BookingGuestEditorComponent,
    FrontpageComponent,
    EventTileComponent,
    MoreComponent,
    FrontpageEventsComponent,
    MyFavoritesComponent,
    FirestoreTimestampPipe,
    BookingStatusPipe,
    LastActionStatusPipe,
    MyTicketsPageComponent,
    EnterCodeComponent,
    FilesizePipe,
    TrimDirective,
    GroupsComponent,
    ParentMeetingsComponent,
    TipsForOrganizersComponent,
    HowToAddEventComponent,
    CampaignRecipientsComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CKEditorModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    ScriptLoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ...firebaseImports,
    MatSlideToggleModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: useDeviceLanguage, useValue: true },
    {
      provide: 'LOCALSTORAGE',
      useFactory: getLocalStorage,
      deps: [PLATFORM_ID]  // Add PLATFORM_ID as a dependency
    },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: LayoutGapStyleBuilder, useClass: CustomLayoutGapStyleBuilder },
    UserTrackingService,
    ScreenTrackingService,
    NgxImageCompressService,
    AuthService,
    AuthGuard,
    MaybeOnlyRegisteredGuard,
    EventService,
    EventListService,
    CategoryService,
    TicketTypeService,
    BookingStatusService,
    VenueService,
    OrganizerService,
    ImportService,
    MapsService,
    NewsletterService,
    SeoService,
    UtilsService,
    RepetitionService,
    ScrollService,
    TeamService,
    CampaignsService,
    BookingService,
    CustomerService,
    FavoritesService,
    CdkColumnDef
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }