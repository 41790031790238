import { EventRepetition } from './event-repetition';
import { Venue } from './venue';
import { Price } from './price';
import { ImageObject } from './image-object';
import { Log } from './log';

export class BookingsStats {
  activeBookings?: number;
  availableTickets?: number;
  adminBookings?: number;
  attendedTickets?: number;
  bookingsCount?: number;
  bookedTickets?: number;
  noShowTickets?: number;
  totalSales?: number;
  waitingListTickets?: number; 
}

export class Event {
  //Number of active tickets for the main event
  activeTickets?: number;
  //Available tickets for the main event
  availableTickets?: number;
  //Age restriction: no-restriction, minimum-age, maximum-age
  ageRestriction: string;
  //Firestore auth id of the user
  author_id: string;
  //Set of booking stats for the event
  bookingsStats?: BookingsStats;
  //Number of days to limit the cancellation of a booking for this event
  cancellationPeriod?: number;
  //Was the cancellation warning sent?
  cancellationWarningSent?: boolean;
  //Category IDs of the event
  categories: string[];
  //Date when the event was created
  created_at?: any;
  //Description of the event in HTMK (english)
  desc_en?: string;
  //Description of the event in HTMK (norwegian)
  desc_nb?: string;
  //Duration of the event in minutes
  duration?: number;
  //Stores "only-me" or the ID of a team
  editableBy?: string;
  //URL of the video to be embedded after the event description
  embeddedVideoURL?: string;
  //End date of the main event
  endDate?: any;
  //End time of the main event
  endTime?: string;
  //Is the event cancelled?
  eventCancelled?: boolean;
  //Absolute URL for the event
  eventLink?: string;
  //Is the event sold out?
  eventSoldOut?: boolean;
  //Event slug, identifier for the URL
  event_slug: string;
  //URL of the event in facebook
  facebookURL?: string;
  //Firestore document ID of the event
  id?: string;
  //Array of images uploaded to the event
  images: ImageObject[];
  //Image in base64 coming from facebook
  imageBase64?: string;
  //Image caption coming from facebook
  imageCaption?: string;
  //The project ID of the project that imported the event into this project
  importedFrom?: string;
  //Is the event featured?
  isFeatured?: string;
  //Logs recording any edition of the event
  logs?: Log[];
  //Maximum allowed age (nullable)
  maximumAge?: number;
  //Minimum allowed age (nullable)
  minimumAge?: number;
  //URL with more info of the event
  moreInfoURL?: string;
  //Mode of the event: 'online' or 'offline'
  mode?: string;
  //Array of organizers of the event (map): containing "organizer" (the id ID of the organizer) and "organizerObj" (a map with the organizers fields)
  organizers: any[];
  //Payment method of the event: 'door' or 'manual_invoice'
  paymentMethod?: string;
  //Array of prices of the event
  prices?: Price[];
  //Publishing date of the event in case publishingOption 'scheduled'
  publishingDate: any;
  //Publishing option: 'now', 'scheduled', 'unlisted'
  publishingOption: string;
  //The publishing time in case publishingOption is 'scheduled'
  publishingTime: string;
  //Has the event the tickets registration enabled?
  registrationEnabled: boolean;
  //Repetitions of the event
  repetitions: EventRepetition[];
  //Firestore document ID of the event in the source firestore project
  sourceEventID?: string;
  //When enabled, the cancellation deadline for all repetitions will be calculated based on the first occurrence, rather than each repetition having its own deadline.
  sameCancellationDeadlineForAllRepetitions?: boolean;
  //Start time of the event
  startTime?: string;
  //Start date of the event
  startDate: any;
  //Status of the event: 'published', 'scheduled', 'unlisted'
  status: string;
  //URL for the streaming of the event
  streamingURL?: string;
  //Firestore document ID of the super event of the event
  super_event?: string;
  //Synchronization options
  synchro?: any;
  //Synchronization destinations
  synchroDestinations?: string[];
  //Tags for the event
  tags?: string;
  //Tickets information for the event: 'noTicketsInfo', 'free', 'ticketsInfo'
  ticketsInformation: string;
  //URL for purchasing tickets of the event
  ticketsURL?: string;
  //Tickets available from date
  ticketsFromDate?: any;
  //Tickets available from time
  ticketsFromTime?: string;
  //Title of the event in norwegian
  title_nb: string;
  //Title of the event in english
  title_en?: string;
  //Type of the event: 'has-super', 'super-event', 'orphan'
  type?: string;
  //Last update of the event
  updated_at?: any;
  //Does the event has a valid cache status?
  validCacheStatus?: boolean;
  //Firestore document ID of the venue for the main event
  venue?: any;
  //Notes concerning the venue of the event introduced by the organizer
  venueNote?: string;
  //Venue object for the main event containing information about the venue
  venueObj?: Venue;
  //URL for videos of the event
  videosURL?: string;
}
