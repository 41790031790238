<div class="wrapper">
  <div class="mat-body privacy-policy mat-elevation-z1 page">
    <div class="container mat-typography">
      <h1 class="mat-headline" translate>HOW_TO_ADD_EVENT.TITLE</h1>

      <div class="content">
        <div *ngIf="translate.currentLang === 'nb'">
          <h2 class="mat-title">Hvordan legge inn et arrangement i «Hva skjer i Stjørdal»</h2>
          <ul>
            <li>Klikk på fanen øverst til venstre og lag deg en bruker</li>
            <li>Finn «+ Legg til et arrangement» og trykk deg videre</li>
            <li>Lag en tittel på ditt arrangement. Det kan også være imøtekommende å lage en engelsk tittel og tekst til
              ditt arrangement for deltakere.</li>
            <li>Lag så en fengende beskrivelse av ditt arrangement</li>
            <li>Finn kategori/er som passer (Da vil deltakere finne dere lettere)</li>
            <li>Velg eller opprett sted for arrangementet – det tydeligere sted, det lettere å finne</li>
            <li>Dato og tid – her kan du også legge til gjentagelser om arrangementet skal gå over flere dager</li>
            <li>Legg til et eller flere passende bilder (maks 5) til ditt arrangement (Bildestr. 800x450 px er å
              foretrekke)</li>
            <li>Under detaljer kan dere legge til tags/stikkord for at folk skal finne dere lettere, om arrangementet er
              et enkeltarrangement, eller tilhører et større arrangement med ulike samlinger. Her setter man også
              aldergrensen for arrangementet.</li>
            <li>Under status og synkronisering kan man endre status på arrangementet om det blir utsolgt eller avlyst,
              samt om man ønsker at arrangementet skal synkroniseres med Visit Norway: Ferie i Norge | Start
              norgesferien på Visit Norway og opplev ditt nærmeste ferieland</li>
            <li>Fanen billetter brukes for å informere om arrangementet er gratis eller har en billettpris. Billettpris
              og lenke til kjøp legges ved.</li>
            <li>Finnes det ulike media/lenker til arrangementet, f. eks et FB-event, en film, Youtube video eller
              lignende, kan dette legges inn under dette punktet</li>
            <li>Under publisering og tillatelser avgjør du om arrangementet skal publiseres med en gang, eller om du
              skal planlegge publisering til et annet tidspunkt. Her setter du også hvem som skal ha rettigheter til å
              redigere arrangementet på denne siden</li>
            <li>Siste punkt er å bekrefte at arrangementet følger publiseringsreglene, satt av nettsideprodusent</li>
          </ul>
          <p>Lykke til med ditt arrangement</p>
        </div>

        <div *ngIf="translate.currentLang === 'en'">
          <h2 class="mat-title">How to add an event in "What's on in Stjørdal"</h2>
          <ul>
            <li>Click on the tab in the upper left corner and create a user</li>
            <li>Find "+ Add an event" and proceed</li>
            <li>Create a title for your event. It can also be welcoming to create an English title and text for your
              event for participants.</li>
            <li>Create an engaging description of your event</li>
            <li>Find suitable category/categories (This will make it easier for participants to find you)</li>
            <li>Select or create a venue for the event – the clearer the location, the easier to find</li>
            <li>Date and time – here you can also add repetitions if the event will run over several days</li>
            <li>Add one or more suitable images (max 5) to your event (Image size 800x450 px is preferred)</li>
            <li>Under details, you can add tags/keywords to make it easier for people to find you, whether the event is
              a single event, or belongs to a larger event with different gatherings. Here you also set the age limit
              for the event.</li>
            <li>Under status and synchronization, you can change the status of the event if it becomes sold out or
              canceled, and also if you want the event to be synchronized with Visit Norway</li>
            <li>The tickets tab is used to inform if the event is free or has a ticket price. Ticket price and link to
              purchase are included here.</li>
            <li>If there are different media/links to the event, e.g., a Facebook event, a film, YouTube video or
              similar, this can be added under this point</li>
            <li>Under publishing and permissions, you decide whether the event should be published immediately, or if
              you should schedule publishing for another time. Here you also set who should have rights to edit the
              event on this page</li>
            <li>The last point is to confirm that the event follows the publishing rules, set by the website producer
            </li>
          </ul>
          <p>Good luck with your event</p>
        </div>
      </div>
    </div>
  </div>
</div>