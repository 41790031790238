<div class="wrapper">
  <div class="campaigns mat-elevation-z1">
    <h1 class="mat-h1" translate>Newsletters</h1>
    <div *ngIf="user?.roles?.admin">
      <div class="project-subscribers-link" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <button mat-stroked-button color="primary" [routerLink]="['/campaign-recipients', 'project-subscribers']">
          <mat-icon>people</mat-icon>
          {{'Project Subscribers' | translate}}
        </button>
      </div>
      <div class="margin-bottom"></div>
      <div fxLayout="column">

        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-lg="start start" fxLayoutGap.lt-lg="10px" class="full-width">
          <div class="note">{{'Next campaign will be automatically scheduled to be sent on next Wednesday at 6 am' | translate }}.</div>
          <div><button (click)="generateCampaign()" mat-raised-button color="primary">{{'Generate now' | translate}}</button></div>
        </div>
        <div class="margin-bottom"></div>

        <h3 class="mat-h3 bold" translate>Most recent</h3>
        <div fxLayout="column" fxLayoutGap="20px">
          <div *ngFor="let campaign of campaigns | async" class="full-width">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="10px">
              <div fxFlex="grow" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
                <div>{{campaign.name}}</div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="subtitle">
                  <div fxLayoutAlign="start center" fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" *ngIf="campaign.status == 'generated'">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <span class="label">{{'Status' | translate }}:</span>
                      <span>{{'Generated' | translate }}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <span class="label">{{'Sending date' | translate }}:</span>
                      <span>{{campaign.scheduledAt?.toDate() |  date:'short':'CET':translate.currentLang }}</span>
                    </div>
                  </div>
                  <div fxLayoutAlign="start center" fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" *ngIf="campaign.status == 'scheduled'">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <span class="label">{{'Status' | translate }}:</span>
                      <span>{{'Scheduled' | translate }}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <span class="label">{{'Sending date' | translate }}:</span>
                      <span>{{campaign.scheduledAt?.toDate() |  date:'short':'CET':translate.currentLang }}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <span class="label">{{'Recipients' | translate }}:</span>
                      <a [routerLink]="['/campaign-recipients', campaign.id]">{{ campaign.recipients | number:'1.0-0':translate.currentLang }}</a>
                    </div>
                  </div>
                  <div fxLayoutAlign="start center" fxLayoutGap="10px" fxLayout="column" *ngIf="campaign.status == 'sent'">
                    <div class="full-width" fxLayoutGap="10px" fxLayout="row" fxLayoutAlign.lt-md="start start">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                        <span class="label">{{'Status' | translate }}:</span>
                        <span>{{'Sent' | translate }}</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                        <span class="label">{{'Date' | translate }}:</span>
                        <span>{{campaign.sentAt?.toDate() |  date:'short':'CET':translate.currentLang }}</span>
                      </div>
                    </div>
                    <div class="full-width" fxLayoutGap="10px" fxLayout="row">
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                        <span class="label">{{'Recipients' | translate }}:</span>
                        <span>{{ campaign.recipients | number:'1.0-0':translate.currentLang }}</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                        <span class="label">{{'Delivered' | translate }}:</span>
                        <span>{{ campaign.delivered | number:'1.0-0':translate.currentLang }} {{ (campaign.delivered ? ('(' + ((campaign.delivered / campaign.recipients) * 100 | number:'1.0-0':translate.currentLang) + '%)') : '0') }}</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                        <span class="label">{{'Views' | translate }}:</span>
                        <span>{{ campaign.views | number:'1.0-0':translate.currentLang }} {{ (campaign.views ? ('(' + ((campaign.views / campaign.recipients) * 100 | number:'1.0-0':translate.currentLang) + '%)') : '0') }}</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                        <span class="label">{{'Clicks' | translate }}:</span>
                        <span>{{ campaign.clicks | number:'1.0-0':translate.currentLang }} {{ (campaign.clicks ? ('(' + ((campaign.clicks / campaign.recipients) * 100 | number:'1.0-0':translate.currentLang) + '%)') : '0') }}</span>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                        <span class="label">{{'Unsubscribes' | translate }}:</span>
                        <span>{{ campaign.unsubscribe | number:'1.0-0':translate.currentLang }} {{ (campaign.unsubscribe ? ('(' + ((campaign.unsubscribe / campaign.recipients) * 100 | number:'1.0-0':translate.currentLang) + '%)') : '0') }}</span>
                      </div>
                    </div>
                  </div>
                  <div fxLayoutAlign="start center" fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" *ngIf="campaign.status == 'draft'">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <span class="label">{{'Status' | translate }}:</span>
                      <span>{{'Draft' | translate }}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <span class="label">{{'Recipients' | translate }}:</span>
                      <a [routerLink]="['/campaign-recipients', campaign.id]">{{ campaign.recipients | number:'1.0-0':translate.currentLang }}</a>
                    </div>
                  </div>
                  <div fxLayoutAlign="start center" fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" *ngIf="campaign.status == 'generating'">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <span class="label">{{'Status' | translate }}:</span>
                      <span>{{'Generating newsletter' | translate }}...</span>
                    </div>
                  </div>
                  <div fxLayoutAlign="start center" fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" *ngIf="campaign.status == 'stopping'">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <span class="label">{{'Status' | translate }}:</span>
                      <span>{{'Stopping newsletter' | translate }}...</span>
                    </div>
                  </div>
                  <div fxLayoutAlign="start center" fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" *ngIf="campaign.status == 'resuming'">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <span class="label">{{'Status' | translate }}:</span>
                      <span>{{'Resuming newsletter' | translate }}...</span>
                    </div>
                  </div>
                  <div fxLayoutAlign="start center" fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" *ngIf="campaign.status == 'error'">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <span class="label">{{'Status' | translate }}:</span>
                      <span>{{'Error' | translate }}...</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <span class="label">{{'Message' | translate }}:</span>
                      <span>{{ campaign.error }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div fxFlex="noshrink" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
                <button *ngIf="campaign.status != 'generating' && campaign.status != 'resuming' && campaign.status != 'stopping'" mat-stroked-button [matMenuTriggerFor]="menu">{{'Actions' | translate}}
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item *ngIf="campaign.status == 'generated' || campaign.status == 'scheduled' || campaign.status == 'error'" (click)="stopCampaign(campaign.id)">
                    <mat-icon>cancel_schedule_send</mat-icon>
                    <span>{{'Stop' | translate}}</span>
                  </button>
                  <button mat-menu-item *ngIf="campaign.status == 'draft'" (click)="resumeCampaign(campaign.id)">
                    <mat-icon>play_circle_outline</mat-icon>
                    <span>{{'Resume' | translate}}</span>
                  </button>
                  <button mat-menu-item *ngIf="campaign.status != 'sent'" (click)="sendTest(campaign.id)">
                    <mat-icon>send</mat-icon>
                    <span>{{'Send a test' | translate}}</span>
                  </button>
                  <a mat-menu-item [routerLink]="'/view-campaign/' + campaign.id">
                    <mat-icon>preview</mat-icon>
                    <span>{{'View' | translate}}</span>
                  </a>
                  <button mat-menu-item *ngIf="campaign.status == 'draft'" [routerLink]="'/edit-campaign/' + campaign.id">
                    <mat-icon>edit</mat-icon>
                    <span>{{'Edit' | translate}}</span>
                  </button>
                  <button color="warn" mat-menu-item *ngIf="campaign.status == 'draft'" (click)="deleteCampaign(campaign.id)">
                    <mat-icon color="warn">delete</mat-icon>
                    <span>{{'Delete' | translate}}</span>
                  </button>
                </mat-menu>
              </div>
            </div>
            <div class="margin-top"></div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!user?.roles?.admin" translate>
      You need to have admin privileges to access this page
    </div>
  </div>
</div>
