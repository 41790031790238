<div class="wrapper">
  <div class="campaign-recipients mat-elevation-z1">
    <h1 class="mat-h1">{{'Campaign Recipients' | translate}}</h1>
    
    <div *ngIf="campaign" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start">
        <button mat-stroked-button [routerLink]="['/campaigns']">
          <mat-icon>arrow_back</mat-icon>
          {{'Back to Campaigns' | translate}}
        </button>
      </div>
      
      <div *ngIf="campaignId === 'project-subscribers'" class="note" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon>info</mat-icon>
        <span>{{'This is the list of all project subscribers' | translate }}</span>
      </div>
      
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex="grow">
          <mat-label>{{'Search Recipients' | translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="searchText" (keyup)="applyFilter()">
          <button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        
        <button mat-raised-button color="primary" [disabled]="loading || !filteredRecipients.length">
          {{ 'Total: ' + filteredRecipients.length }}
        </button>
      </div>

      <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>

      <div *ngIf="!loading && error" class="error-message">
        <mat-icon color="warn">error</mat-icon>
        <span>{{error}}</span>
      </div>

      <mat-card *ngIf="!loading && !error && !filteredRecipients.length" class="empty-state">
        <mat-card-content fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
          <mat-icon>people</mat-icon>
          <span *ngIf="searchText">{{'No recipients match your search criteria' | translate}}</span>
          <span *ngIf="!searchText">{{'No recipients found for this campaign' | translate}}</span>
        </mat-card-content>
      </mat-card>

      <div *ngIf="!loading && filteredRecipients.length">
        <mat-card *ngFor="let recipient of filteredRecipients" class="recipient-card" fxLayout="row" fxLayoutAlign="space-between center">
          <span>{{recipient.email}}</span>
          <button mat-icon-button color="warn" (click)="openRemoveDialog(recipient)" [disabled]="recipient.removing">
            <mat-icon *ngIf="!recipient.removing">delete</mat-icon>
            <mat-spinner *ngIf="recipient.removing" [diameter]="20"></mat-spinner>
          </button>
        </mat-card>
      </div>
    </div>

    <div *ngIf="!campaign" fxLayout="row" fxLayoutAlign="center center">
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
  </div>
</div>